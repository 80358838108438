import Breakpoints from '@utils/breakpoints';
import Dimensions from '@utils/dimensions';

const Styles = {
  container: {
    maxWidth: Dimensions.contentMaxWidth,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justfyContent: 'space-between',
    padding: '80px 0',
    flexWrap: 'wrap',
    [`@media (max-width: ${Breakpoints.s})`]: {
      paddingTop: '0',
      paddingBottom: '40px',
    },
  },
  colImage: {
    width: '50%',
    padding: '0 20px 0 0',
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '100%',
      padding: '0 20px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      padding: '0',
      margin: '0 -20px',
    },
  },
  image: {
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: 'calc(100% + 40px)',
      maxWidth: 'calc(100% + 40px)',
    },
  },
  colText: {
    width: '50%',
    padding: '0 100px 0 0 ',
    fontSize: '20px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '100%',
      padding: '20px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '16px',
      padding: '25px 0 0 0',
    },
  },
  text: {
    marginBottom: '50px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      marginBottom: '30px',
    },
  },
};

export default Styles;
