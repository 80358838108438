import React from 'react';

import Layout from '@layout/Layout';
import PageContainer from '@layout/PageContainer';
import Seo from '@components/Seo/Seo';

import PageHeader from '@components/PageHeader/PageHeader';
import Newsletter from '@components/Newsletter/Newsletter';

import HowCanHelp from '@components/HowCanHelp/HowCanHelp';

const ComeFunziona = ({ location }) => {
  const title = 'Come posso aiutarti';
  const subtitle =
    'Intuitive Eating per fare finalmente pace con corpo, cibo e movimento.';

  return (
    <Layout>
      <Seo title={title} description={subtitle} url={location.pathname} />
      <PageContainer noBottomMargin>
        <PageHeader title={title} subtitle={subtitle} nomargin />
        <HowCanHelp />
        <Newsletter />
      </PageContainer>
    </Layout>
  );
};

export default ComeFunziona;
