import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import Utils from '@utils/';
import Button from '@components/Button/Button';

import Styles from './ImgHtmlButton.styles';

const useStyles = createUseStyles(Styles);

const ImgHtmlButton = ({
  image,
  imageAlt,
  text,
  buttonLabel,
  buttonLink,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <div className={classes.colText}>
        <div
          className={classes.text}
          dangerouslySetInnerHTML={Utils.createMarkup(text)}
        ></div>
        {buttonLink && buttonLabel ? (
          <Button to={buttonLink} label={buttonLabel} />
        ) : null}
      </div>
      <div className={classes.colImage}>
        <img className={classes.image} src={image} alt={imageAlt} />
      </div>
    </div>
  );
};

ImgHtmlButton.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default ImgHtmlButton;
