import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import Styles from './PageContainer.styles';

const useStyles = createUseStyles(Styles);

const PageContainer = ({ children, noBottomMargin, ...props }) => {
  const classes = useStyles(props);

  return (
    <div
      className={`${classes.container} ${
        noBottomMargin ? classes.containerNoMarginBottom : ''
      }`}
    >
      {children}
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.any,
};

export default PageContainer;
