import Colors from '@utils/colors';
import Breakpoints from '@utils/breakpoints';

const Styles = {
  container: {
    padding: '80px 20px 60px 20px',
    '@global': {
      a: {
        color: Colors.accent,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    [`@media (max-width: ${Breakpoints.m})`]: {
      paddingTop: '100px',
    },
  },
  containerNoMarginBottom: {
    paddingBottom: 0,
  },
};

export default Styles;
