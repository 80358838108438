import React from 'react';
import { createUseStyles } from 'react-jss';

import Button from '@components/Button/Button';

import Tina1 from '@static/img/tina4.jpg';
import Tina2 from '@static/img/tina3.jpg';
import Singoli from '@static/img/percorsi-singoli.jpg';
import Gruppo from '@static/img/percorsi-gruppo.jpg';

import ImgHtmlButton from '@components/ImgHtmlButton/ImgHtmlButton';

import Styles from './HowCanHelp.styles';

const useStyles = createUseStyles(Styles);

const HowCanHelp = props => {
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.containerTransparent}>
        <div className={classes.content}>
          <div className={classes.row}>
            <div className={classes.fieldLeftSmall}>
              <img src={Tina2} alt="Tina Olsen" />
            </div>
            <div className={classes.fieldRightLarge}>
              <h3>Il mio approccio</h3>
              <p>
                Ero convinta di aver perso l'abilità di sentirmi sazia al punto
                giusto. Contavo le calorie perché non avevo fiducia nel mio
                corpo. E così mi regolavo interamente con gli strumenti che mi
                metteva a disposizione la cultura della dieta.
              </p>
              <p>
                Nel frattempo, dall'esperienza personale e dal lavoro con i miei
                clienti, ho imparato che tutti possiamo riprendere la
                connessione con il nostro corpo e fare davvero pace con il cibo.
                Per questo bisogna però lavorare per abbandonare il desiderio di
                voler cambiare aspetto fisico e/o in tanti casi, anche la paura
                di ingrassare.
              </p>
              <p>
                Dobbiamo abituarci al fatto che non possiamo controllare il
                nostro corpo alla perfezione con cibo e allenamento, almeno non
                se vogliamo vivere in maniera serena.
              </p>
              <p>
                Aiuto le donne a fare pace con corpo, cibo e movimento facendo
                uso del coaching per migliorare l'immagine corporea, insieme al
                mindfulness (riferito al movimento ma non solo).
              </p>
              <p>
                <a href="/chi-sono" title="Chi sono">
                  Qui
                </a>{' '}
                puoi vedere la mia formazione relativa al mio lavoro.
              </p>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.center}>
              <br />
              <br />
              <p>
                <strong>
                  Sei pronta a dire BASTA e a riprendere in mano la tua vita?
                </strong>
                <br />
                Prenota qui una consulenza gratuita.
              </p>
              <br />
              <Button to="/consulenza-gratuita" label="Consulenza gratuita" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.row}>
            <div className={classes.fieldLeft}>
              <p>
                Da quando avevo 9 anni ho capito di “dover fare attenzione” a
                cosa mangiavo per non ingrassare.
              </p>
              <p>
                E così, per quasi 40 anni mi sono fatta guidare dalla cultura
                della dieta, all'inizio per dimagrire e poi per paura di
                ingrassare, senza mai chiedermi se il controllo, l'allenamento
                ossessivo, il pianificare ogni pasto, il contare calorie, le
                restrizioni, la deprivazione e la rinuncia a cene improvvisate o
                a una fetta di torta appena sfornata, valeva veramente la pena.
              </p>
              <p className={classes.large}>… è la storia di tante donne</p>
              <p>
                Sfortunatamente però, le diete (compresi i "
                <strong>non-è-una-dieta-ma-uno-stile-di-vita</strong>") che ci
                promettono di diventare più sane, aumentare l'energia, sgonfiare
                la pancia, disintossicarci, rimetterci in forma, proteggere il
                clima e così via, non funzionano per cambiare l'aspetto fisico.
              </p>
              <p>
                Le diete con tutte le loro regole ci scollegano dalla saggezza
                nostro corpo.
              </p>
              <p>
                <strong>
                  Sei pronta a dire BASTA e a riprendere in mano la tua vita?
                </strong>
              </p>
              <Button to="/consulenza-gratuita" label="Consulenza gratuita" />
            </div>
            <div className={classes.fieldRight}>
              <img src={Tina1} alt="Tina Olsen" />
            </div>
          </div>
        </div>
      </div> */}

      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.row}>
            <div className={classes.field}>
              <h3 className={classes.titleCenter}>
                Che cos'è l'Intuitive Eating?
              </h3>
              <p className={classes.center}>
                Intuitive Eating ovvero l'alimentazione intuitiva è un percorso
                basato su 10 principi che ti insegnano come ascoltare il proprio
                corpo e avere fiducia nella sua capacità di decidere come
                mangiare e muoversi nella maniera giusta per te, senza lasciarti
                influenzare dalla cultura della dieta.
              </p>
            </div>
          </div>
        </div>
      </div>

      <ImgHtmlButton
        image={Tina1}
        imageAlt="Scopri i corsi"
        text="<div><h3>Ho creato dei percorsi di Intuitive Eating per aiutarti a</h3><ul>
          <li>
            Accettare e apprezzare il tuo corpo così com'è in questo momento
          </li>
          <li>
            Uscire dal ciclo di restrizioni e abbuffate per trovare equilibrio e
            serenità
          </li>
  
          <li>
            Assegnare lo stesso valore emotivo a tutti i cibi per non sentirti più
            fuori controllo
          </li>
          <li>
            Capire l“emotional eating” e trovare altri strumenti oltre al cibo per
            affrontare le emozioni
          </li>
          <li>
            Rispettare e ascoltare il tuo corpo e fidarti dei suoi segnali di fame
            e sazietà
          </li>
          <li>
            Scegliere cosa mangiare basato su self-care e abbondanza invece di
            controllo e restrizioni
          </li>
          <li>
            Riscoprire il movimento come un atto d'amore e usarlo come strumento
            efficace per riconnetterti con il tuo corpo (questa è una possibilità
            in più, non obbligatoria)
          </li>
          <li>
            Liberarti per sempre dalla mentalità della dieta per riprendere in
            mano la tua vita
          </li>
        </ul>
  
        <p>… e fare finalmente pace con corpo, cibo e movimento.</p></div>"
      />

      <div className={classes.container}>
        <div className={classes.content}>
          <h3 className={classes.titleCenter}>
            Scegli il percorso che fa per te
          </h3>
          <div className={classes.row}>
            <div className={classes.fieldLeft}>
              <h3 className={classes.fieldLeftTitle}>One-to-One</h3>
              <img src={Singoli} alt="Percorsi One-to-One" />
              <p>
                Nella modalità “One-to-One” ci vediamo - soltanto io e te -
                online per una sessione di 60 minuti alla settimana per 12
                settimane (la prima sessione introduttiva dura 1,5 ora). Questo
                per te che vorresti avere un approccio molto personale. Avrai la
                possibilità di fissare gli orari che funzionano meglio per te e
                in più avrai la mia attenzione al 100 %.
              </p>
              <p>
                Dopo il periodo di 12 settimane puoi scegliere se continuare con
                le sessioni in base alle tue necessità.{' '}
              </p>
            </div>
            <div className={classes.fieldRightLarge}>
              <h3 className={classes.fieldLeftTitle}>Corso di gruppo</h3>
              <img src={Gruppo} alt="Percorsi di gruppo" />
              <p>
                Due volte all'anno iniziamo un percorso di 8 settimane (1 ora
                alla settimana) online con un massimo di 8 partecipanti,
                esclusivamente donne. Il corso che ti introduce ai 10 principi
                dell'Intuitive Eating è un'occasione perfetta per confrontarti,
                discutere, fare delle domande e trarre grande beneficio dalla
                dinamicità di gruppo. Capirai che non sei da sola con le tue
                sfide e troverai ispirazione e sostegno per affrontarle.
              </p>
              <p>
                Dopo il periodo di 8 settimane oppure durante, puoi scegliere
                anche la modalità One-to-One per integrare davvero i 10 principi
                nella tua vita e diventare un “Intuitive Eater.”
              </p>
            </div>
          </div>
          <div className={classes.row}>
            <div>
              <h3>In più avrai </h3>
              <ul>
                <li>
                  Possibilità di contattarmi via email alla quale cerco di
                  rispondere entro 24 ore
                </li>
                {/* <li>
                  <strong>
                    Gruppo Facebook aperto esclusivamente alle donne che hanno
                    partecipato a un percorso One-to-One o di gruppo per
                    discutere, fare delle domande, parlare con altre persone che
                    hanno lo stesso scopo: cioè cambiare la loro relazione con
                    cibo e corpo (con questa dobbiamo aspettare)
                  </strong>
                </li> */}
                <li>
                  Una lezione di gruppo al mese di Movimento Intuitivo a seconda
                  della disponibilità e all'orario disponibile sul sito, a
                  scelta tra quattro lezioni diverse ogni settimana{' '}
                </li>
                <li>“Compiti” settimanali</li>
                <li>
                  Worksheets per riflessioni, supporto e ulteriore sviluppo
                </li>
                <li>
                  Video di 10 Mindful Movements da fare quando vuoi, per
                  aiutarti a riconnettere corpo e mente
                </li>
              </ul>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.center}>
              <br />
              <br />
              <strong>
                Il percorso One-to-One ha un costo complessivo di €1000,00
              </strong>
              <br />
              <strong>
                Il Corso di gruppo ha un costo complessivo di €480,00
              </strong>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.containerTransparent}>
        <div className={classes.content}>
          <div className={classes.row}>
            <div className={classes.center}>
              <strong>
                Sei pronta a dire BASTA e a riprendere in mano la tua vita?
              </strong>
              <br />
              Prenota qui una consulenza gratuita.
              <br />
              <br />
              <Button to="/consulenza-gratuita" label="Consulenza gratuita" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowCanHelp;
