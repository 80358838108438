import Colors from '@utils/colors.js';
import Dimensions from '@utils/dimensions';
import Breakpoints from '@utils/breakpoints';

const Styles = {
  container: {
    background: Colors.alternateBg,
    padding: '60px 20px',
    fontSize: '20px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      padding: '30px 20px 60px',
    },
  },
  containerTransparent: {
    padding: '60px 20px',
  },
  content: {
    maxWidth: Dimensions.contentMaxWidth,
    margin: '0 auto',
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  fieldLeft: {
    width: '50%',
    paddingRight: '30px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      paddingRight: '0',
      marginBottom: '40px',
    },
  },
  fieldRight: {
    width: '40%',
    paddingLeft: '30px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      paddingLeft: '0',
    },
  },
  large: {
    fontWeight: '700',
    fontSize: '22px',
  },
  fieldLeftSmall: {
    width: '40%',
    paddingRight: '30px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      paddingRight: '0',
      marginBottom: '40px',
    },
  },
  fieldRightLarge: {
    width: '50%',
    paddingLeft: '30px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      paddingLeft: '0',
    },
  },
  center: {
    textAlign: 'center',
  },
  titleCenter: {
    fontSize: '32px',
    textAlign: 'center',
  },
  fieldLeftTitle: {
    fontSize: '24px',
    marginBottom: '10px',
  },
};

export default Styles;
